<script>
import { Link } from "@inertiajs/inertia-vue3";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/vue";

export default {
    props: {
        active: {
            type: String,
            default: "EditProfile"
        },
        order_count: Number
    },
    components: {
        Link,
        Disclosure,
        DisclosureButton,
        DisclosurePanel
    },
    methods: {
        isAtLink(val) {
            return this.active == val;
        }
    }
};
</script>

<template>
    <div class="w-full max-w-[300px] 2xl:h-50 flex flex-col justify-start pt-12 items-start pl-10 border-2 rounded-lg">
        <!--        <div class="flex pb-5">-->
        <!--            <Link href="/siparislerim" class="text-lg text-gray-600 font-medium tracking-wide" :class="{ '!text-black': isAtLink('MyOrders') }">Siparişler </Link>-->
        <!--        </div>-->
        <!--        <div class="flex justify-start items-center pb-5">-->
        <!--            <Link href="/profili-duzenle" class="text-lg text-gray-600 font-medium tracking-wide" :class="{ '!text-black': isAtLink('EditProfile') }">Profili Düzenle</Link>-->
        <!--        </div>-->
        <div class="pb-5">
            <Disclosure v-slot="{ open }">
                <DisclosureButton class="flex justify-start items-center w-full">
                    <div class="text-lg text-gray-600 font-medium tracking-wide" :class="{ '!text-black': isAtLink('EditProfile') }">Profilim</div>
                    <svg class="ml-3" xmlns="http://www.w3.org/2000/svg" width="14.746" height="7.51" viewBox="0 0 14.746 7.51">
                        <path
                            id="arrow-ios-downward-fill"
                            d="M14.859,21.006a1.039,1.039,0,0,1-.673-.247L7.878,15.4a1.088,1.088,0,0,1-.137-1.512,1.039,1.039,0,0,1,1.482-.139l5.636,4.8,5.636-4.632a1.038,1.038,0,0,1,1.482.161,1.086,1.086,0,0,1-.147,1.566l-6.308,5.179A1.036,1.036,0,0,1,14.859,21.006Z"
                            transform="translate(-7.498 -13.498)"
                            fill="#231f20"
                        />
                    </svg>
                </DisclosureButton>
                <DisclosurePanel class="ml-4 pt-2">
                    <div class="flex justify-start items-center pb-2">
                        <Link href="/profili-duzenle" class="text-base text-gray-600 font-medium tracking-wide" :class="{ '!text-black': isAtLink('EditProfile') }">Hesap Bilgilerim</Link>
                    </div>
                    <div class="flex justify-start items-center pb-2">
                        <Link href="/sifremi-yenile" class="text-base text-gray-600 font-medium tracking-wide" :class="{ '!text-black': isAtLink('ChangePassword') }">Şifre Değişikliği</Link>
                    </div>
                    <div class="flex justify-start items-center">
                        <Link href="/iletisim-bilgileri" class="text-base text-gray-600 font-medium tracking-wide" :class="{ '!text-black': isAtLink('ContactInformation') }">İletişim Bilgilerim</Link>
                    </div>
                </DisclosurePanel>
            </Disclosure>
        </div>
        <div class="flex pb-5">
            <Link href="/kiralamalar" class="text-lg text-gray-600 font-medium tracking-wide" :class="{ '!text-black': isAtLink('Hiring') }">Kiralamalarım</Link>
        </div>
        <!--        <div class="flex items-center pb-5">-->
        <!--            <Link href="/destek-taleplerim" class="text-lg text-gray-600 font-medium tracking-wide" :class="{ '!text-black': isAtLink('SupportRequests') }">Destek Talepleri</Link>-->
        <!--        </div>-->
        <div class="pb-5">
            <Disclosure v-slot="{ open }">
                <DisclosureButton class="flex justify-start items-center w-full">
                    <Link href="/destek-taleplerim" class="text-lg text-gray-600 font-medium tracking-wide" :class="{ '!text-black': isAtLink('SupportRequests') }">Destek Taleplerim</Link>
                    <svg class="ml-3" xmlns="http://www.w3.org/2000/svg" width="14.746" height="7.51" viewBox="0 0 14.746 7.51">
                        <path
                            id="arrow-ios-downward-fill"
                            d="M14.859,21.006a1.039,1.039,0,0,1-.673-.247L7.878,15.4a1.088,1.088,0,0,1-.137-1.512,1.039,1.039,0,0,1,1.482-.139l5.636,4.8,5.636-4.632a1.038,1.038,0,0,1,1.482.161,1.086,1.086,0,0,1-.147,1.566l-6.308,5.179A1.036,1.036,0,0,1,14.859,21.006Z"
                            transform="translate(-7.498 -13.498)"
                            fill="#231f20"
                        />
                    </svg>
                </DisclosureButton>
                <DisclosurePanel class="ml-4 pt-2">
                    <div class="flex justify-start items-center pb-2">
                        <Link href="/gecmis-taleplerim" class="text-base text-gray-600 font-medium tracking-wide" :class="{ '!text-black': isAtLink('OldRequest') }">Geçmiş Taleplerim</Link>
                    </div>
                    <div class="flex justify-start items-center" v-if="order_count > 0">
                        <Link href="/talep-olustur" class="text-base text-gray-600 font-medium tracking-wide" :class="{ '!text-black': isAtLink('CreateRequest') }">Talep Oluştur</Link>
                    </div>
                </DisclosurePanel>
            </Disclosure>
        </div>
        <div class="flex pb-5">
            <Link href="/adreslerim" class="text-lg text-gray-600 font-medium tracking-wide" :class="{ '!text-black': isAtLink('Addresses') }">Adreslerim</Link>
        </div>
        <div class="hidden pb-5">
            <Link href="istek-listem-tavsiye" class="text-lg text-gray-600 font-medium tracking-wide" :class="{ '!text-black': isAtLink('Advice') }">Tavsiye Davetlerim</Link>
        </div>
        <div class="flex pb-5">
            <Link href="/istek-listem" class="text-lg text-gray-600 font-medium tracking-wide" :class="{ '!text-black': isAtLink('MyWishlists') }">İstek Listem</Link>
        </div>
        <!--        <div class="flex pb-5">-->
        <!--            <Link href="/kuponlarim" class="text-lg text-gray-600 font-medium tracking-wide"-->
        <!--                  :class="{ '!text-black': isAtLink('Coupons') }">Kuponlar-->
        <!--            </Link>-->
        <!--        </div>-->
        <div class="flex pb-5">
            <Link href="/odeme-yontemlerim" class="text-lg text-gray-600 font-medium tracking-wide" :class="{ '!text-black': isAtLink('PaymentMethods') }"> Ödeme Yöntemlerim</Link>
        </div>
        <div class="flex pb-5">
            <Link href="/cikis-yap" class="text-lg text-gray-600 font-medium tracking-wide">Çıkış</Link>
        </div>
    </div>
</template>

<style scoped></style>
